/* eslint-disable no-console,no-undef,camelcase,no-useless-escape */
import moment from 'moment-timezone';
import { TimeZone } from './constants';
import _ from 'lodash';
import { Distance, Steps, Calories } from '../components/SvgComponents';
import { BASE_URL } from './config';
// import { toast } from 'react-toastify';
import DOMPurify from 'dompurify';
// import React from 'react';
import ReactHtmlParser from 'react-html-parser';

const components = {
  Distance: Distance,
  Steps: Steps,
  Calories: Calories
};

const units = {
  "year": 24*60*365,
  "month": 24*60*30,
  "week": 24*60*7,
  "day": 24*60,
  "hour": 60,
  "minute": 1
};

export const getUnitToShow = (data, unit) => (Math.floor(data)) <= 1 ? `${Math.floor(data)} ${unit}` : `${Math.floor(data)} ${unit}s`;

export const convertDateInTimezone = (date) => moment(date).tz(TimeZone);

export const descendingOrderSort = (data) => data.sort((a,b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()).reverse();

export const getComponentName = (name) => components[name];

export const getLength = (weeks, remaining_days, hours, minutes) => {
  let arr = [];
  if(weeks >= 1) {
    if(remaining_days >= 6 && hours >= 23 && minutes >= 59){
      let obj = {};
      obj.value = weeks+1;
      obj.unit = 'week';
      arr.push(obj);
    }
    else{
      let obj = {};
      obj.value = weeks;
      obj.unit = 'week';
      arr.push(obj);
    }
  }
  if(remaining_days >= 1 ) {
    if (remaining_days < 6) {
      if(hours >= 23 && minutes >= 59){
        let obj = {};
        obj.value = remaining_days+1;
        obj.unit = 'day';
        arr.push(obj);
      }
      else{
        let obj = {};
        obj.value = remaining_days;
        obj.unit = 'day';
        arr.push(obj);
      }
    }
    else {
      if (hours >= 23 && minutes >= 59 && weeks===0) {
        let obj = {};
        obj.value = 1;
        obj.unit = 'week';
        arr.push(obj);
      }
    }
  }else{
    if(hours >= 23 && minutes >= 59 && weeks >= 1){
      let obj = {};
      obj.value = 1;
      obj.unit = 'day';
      arr.push(obj);
    }
  }
  
  if(hours >= 1) {
    if(hours < 23) {
      let obj = {};
      obj.value = hours;
      obj.unit = 'hour';
      if (arr.length < 2) {
        arr.push(obj);
      }
    }
  }

  if(minutes >= 1) {
    if(minutes < 59){
      let obj = {};
      obj.value = minutes;
      obj.unit = 'minute';
      if(arr.length < 2 ) {
        arr.push(obj);
      }
    } else {
      if(hours >= 23 && minutes >= 59 && remaining_days === 0 && weeks === 0){
        let obj = {};
        obj.value = 1;
        obj.unit = 'day';
        arr.push(obj);
      }
    }
  }
  return arr.map((step, index) => getUnitToShow(step.value, step.unit) + (index === (arr.length - 1) ? '' : ', '))
};

export const getChallengeDays = (firstDate, secondDate) => moment(firstDate, 'MM-DD-YYYY').diff(moment(secondDate), 'days');
export const getChallengeTimeToShow = (item) => {
  let result = 0;
  let newResult = [];
  if (convertDateInTimezone(item?.start_date)._d <= moment()._d) {
    result = Math.abs(getChallengeDays(convertDateInTimezone(item?.end_date)._d, moment()._d));
    if(result === 0){
      newResult.push("Last Day");
    } else if( result === 1){
      newResult.push(result + " " + "Day");
    }
  } else if (convertDateInTimezone(item?.start_date).add(1, 'days')._d > moment()._d) {
    result = Math.abs(getChallengeDays(convertDateInTimezone(item?.start_date).add(1, 'days')._d, moment()._d));
    if(result === 1){
      newResult.push(result + " " + "Day");
    }
  }
  if(result > 1){
    newResult.push(result-1 +" "+ "Days");
  }
  return newResult;
}

export const getChallengeTimeToShowNew = (item, props) => {
  let result = 0;
  let newResult = [];

  // Convert dates to the specific timezone
  const startDate = convertDateInTimezone(item?.start_date);
  const endDate = convertDateInTimezone(item?.end_date);
  const currentDate = moment();

  // Check if the challenge has started
  if (startDate.isSameOrBefore(currentDate)) {
    result = Math.abs(getChallengeDays(endDate, currentDate));
    if (result === 0) {
      newResult.push(props.t("Last Day"));
    } else if (result === 1) {
      newResult.push(result + " " + props.t("Day"));
    }
  } 
  // Check if the challenge is about to start
  else if (startDate.add(1, 'days').isAfter(currentDate)) {
    result = Math.abs(getChallengeDays(startDate, currentDate));
    if (result === 1) {
      newResult.push(result + " " + props.t("Day"));
    }
  }

  // If there are more than 1 days remaining, display the remaining days correctly
  if (result > 1) {
    newResult.push(result + " " + props.t("Days"));
  }

  return newResult;
}
export const getMainConstraint = (firstDate, secondDate) => moment(firstDate, 'MM-DD-YYYY').diff(moment(secondDate, 'MM-DD-YYYY'), 'minutes');
export const getMainConstraintInSeconds = (firstDate, secondDate) => moment(firstDate, 'MM-DD-YYYY').diff(moment(secondDate, 'MM-DD-YYYY'), 'seconds')

export const getTimeToShow = (item, value) => {
  let result = 0;
  if(value === 'challenge') {
    if (convertDateInTimezone(item?.start_date)._d <= moment()._d) {
      result = Math.abs(getMainConstraint(convertDateInTimezone(item?.end_date)._d, moment()._d));
    } else if (convertDateInTimezone(item?.start_date)._d > moment()._d) {
      result = Math.abs(getMainConstraint(convertDateInTimezone(item?.start_date)._d, moment()._d));
    }
    //   } else if(item.weeks === 1){
    //     let first = convertDateInTimezone(item?.start_date)._d;
    //     let last = convertDateInTimezone(item?.end_date)._d;
    //     if(10080 >= Math.abs(getMainConstraint(first, last)) && convertDateInTimezone(item?.start_date).add(1, 'days')._d <= moment()._d) {
    //       result = Math.abs(getMainConstraint(convertDateInTimezone(item?.start_date).add(1, 'days')._d, moment()._d));
    //     }
    //     else if(convertDateInTimezone(item?.start_date).add(7, 'days')._d > moment()._d){
    //       result = Math.abs(getMainConstraint(convertDateInTimezone(item?.start_date).add(7, 'days')._d, moment()._d));
    //     }
    //   } else if (item.weeks > 1) {
    //     if(convertDateInTimezone(item?.start_date).add(7, 'days')._d > moment()._d) {
    //       result = Math.abs(getMainConstraint(convertDateInTimezone(item?.start_date).add(7, 'days')._d, moment()._d));
    //     } else {
    //       result = Math.abs(getMainConstraint(convertDateInTimezone(item?.end_date)._d, moment()._d));
    //     }
    //   }
    // }
  }
  else if(value === 'event' &&  moment()._d <= convertDateInTimezone(item?.end_date)._d) {
    result = convertDateInTimezone(item?.start_date)._d > moment()._d ? Math.abs(getMainConstraint(convertDateInTimezone(item?.start_date)._d, moment()._d)) : Math.abs(getMainConstraint(moment()._d, convertDateInTimezone(item?.end_date)._d));
  } else if(value === 'feeds') {
    result = Math.abs(getMainConstraint(moment()._d, convertDateInTimezone(item?.created_at)._d ));
  }

  let newResult = [];
  for(let name in units) {
    let p =  Math.floor(result/units[name]);
    if(newResult.length < 2) {
      if(p === 1) newResult.push(p + " " + name);
      if(p >= 2) newResult.push(p + " " + name + "s");
      result %= units[name];
    }
  }

  return newResult;
};
export const getLastSyncDetails = (synctime) => {
  const units = {
    "year": 24*60*365*60,
    "month": 24*60*30*60,
    "week": 24*60*7*60,
    "day": 24*60*60,
    "hour": 60*60,
    "minute": 60,
    "second":1
  };
  let result = Math.abs(getMainConstraintInSeconds(convertDateInTimezone(synctime)._d, moment()._d));
  let newResult = [];
  for(let name in units) {
    let p =  Math.floor(result/units[name]);
    if(newResult.length < 2) {
      if(p === 1) newResult.push(p + " " + name);
      if(p >= 2) newResult.push(" "+p + " " + name + "s");
      result %= units[name];
    }
  }
  return newResult;

};
export const checkDates = (item) => {
  let result = false;
  let first = convertDateInTimezone(item?.start_date)._d;
  let last = convertDateInTimezone(item?.end_date)._d;
  if(Math.abs(getMainConstraint(first, last)) <= 10080 && convertDateInTimezone(item?.start_date).add(1, 'days')._d <= moment()._d) {
    result = true;
  }
  else if(convertDateInTimezone(item?.start_date).add(7, 'days')._d <= moment()._d){
    result = true;
  } 
  return result;
}

export const getStatus = (challenge) => {
  let isChallengeLocked = false;
  const challengeLength = getLength(challenge.weeks, challenge.remaining_days, challenge.hours, challenge.minutes);
  const weeks = challengeLength.length && challengeLength[0].includes('weeks') ? challengeLength[0].split(' ')[0] : challenge.weeks;
  // challenge &&
  // (weeks <= 1 && convertDateInTimezone(challenge.start_date).add(1, 'days')._d <= moment()._d) ? (isChallengeLocked = true) :
  //   ((weeks > 1 && weeks < 4) && convertDateInTimezone(challenge.start_date).add(7, 'days')._d <= moment()._d) ? (isChallengeLocked = true) :
  //     (weeks >= 4 && convertDateInTimezone(challenge.start_date).add(14, 'days')._d <= moment()._d) ? (isChallengeLocked = true) : isChallengeLocked = false;
  if(weeks <= 1 && convertDateInTimezone(challenge?.start_date).add(1, 'days')._d <= moment()._d){
    isChallengeLocked = true
  }
  else if((weeks > 1 && weeks < 4) && convertDateInTimezone(challenge?.start_date).add(7, 'days')._d <= moment()._d){
    isChallengeLocked = true
  }
  else if(weeks >= 4 && convertDateInTimezone(challenge?.start_date).add(14, 'days')._d <= moment()._d){
    isChallengeLocked = true
  }
  else{
    isChallengeLocked = false
  }
  return isChallengeLocked;
};


export const commaSeperator = (num) => {
  if(_.isNull(num) || _.isUndefined(num)) {
    return
  }
  else {
    let values = num.toString().split('.');
    return values[0].replace(/.(?=(?:.{3})+$)/g, '$&,') + ( values.length === 2 ? '.' + values[1] : '' )
  }
};

const EmailWithLTDPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isValidEmail = (email) => EmailWithLTDPattern.test(email);

export const convertUnicode = (input) =>
  input.replace(/\\u(\w{4,4})/g, (a,b) => {
    let charcode = parseInt(b,16);
    return String.fromCharCode(charcode);
  });

export const getDecodedString = (str) => {
  const input = str.replace(/<[^>]*>/g, '');
  return convertUnicode(input);
};

export const applyHighlights = (userArray, userIdArray, textToReplace) => {
  let text2 = textToReplace;
  if (Array.isArray(userArray)) {
    userArray.forEach((user, index) => {
      let escapedUser = user?.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      // let regex = new RegExp(/* user */ escapedUser, "g");
      // let regex = new RegExp(user, "g");
      // text2 = text2.replace(/\n$/g, '\n\n').replace(regex, `<span id="${userIdArray[index]}">${/* user */ escapedUser}</span>`);

      let replacement = `<span id="${userIdArray[index]}">${escapedUser}</span>`;
      text2 = text2.split(user).join(replacement);
    });
  }

  return text2;
}

export const getOrientation = (file, callback) => {
  let reader = new FileReader();

  reader.onload = function(event) {
    let view = new DataView(event.target.result);

    if (view.getUint16(0, false) !== 0xFFD8) return callback(-2);

    let length = view.byteLength,
      offset = 2;

    while (offset < length) {
      let marker = view.getUint16(offset, false);
      offset += 2;

      if (marker === 0xFFE1) {
        if (view.getUint32(offset += 2, false) !== 0x45786966) {
          return callback(-1);
        }
        let little = view.getUint16(offset += 6, false) === 0x4949;
        offset += view.getUint32(offset + 4, little);
        let tags = view.getUint16(offset, little);
        offset += 2;

        for (let i = 0; i < tags; i++)
          if (view.getUint16(offset + (i * 12), little) === 0x0112)
            return callback(view.getUint16(offset + (i * 12) + 8, little));
      }
      else if ((marker & 0xFF00) !== 0xFF00) break;
      else offset += view.getUint16(offset, false);
    }
    return callback(-1);
  };

  reader.readAsArrayBuffer(file.slice(0, 64 * 1024));
};

export const resetOrientation = (source, sourceOrientation, callback) => {
  let img = new Image();
  img.onload = function () {
    let width = img.width,
      height = img.height,
      canvas = document.createElement('canvas'),
      ctx = canvas.getContext("2d");

    // set proper canvas dimensions before transform & export
    if(sourceOrientation > 4 && sourceOrientation < 9) {
      canvas.width = height;
      canvas.height = width;
    } else {
      canvas.width = width;
      canvas.height = height;
    }

    // transform context before drawing image
    switch (sourceOrientation) {
    case 2:
      ctx.transform(-1, 0, 0, 1, width, 0);
      break;
    case 3:
      ctx.transform(-1, 0, 0, -1, width, height);
      break;
    case 4:
      ctx.transform(1, 0, 0, -1, 0, height);
      break;
    case 5:
      ctx.transform(0, 1, 1, 0, 0, 0);
      break;
    case 6:
      ctx.transform(0, 1, -1, 0, height, 0);
      break;
    case 7:
      ctx.transform(0, -1, -1, 0, height, width);
      break;
    case 8:
      ctx.transform(0, -1, 1, 0, 0, width);
      break;
    default:
      break;
    }

    // draw image
    ctx.drawImage(img, 0, 0);

    // export base64
    callback(canvas.toDataURL());
  };

  img.src = source;
};

export const getUnit = (value) => {
  let number = value && value.toString().replace(/,/g, "");
  if(number) {
    let arr = number.toString().split('.')
    return arr.length === 2 ? commaSeperator(parseFloat(number).toFixed(2)) : commaSeperator(parseInt(number,10));
  }
  else return 0;
}

export const findMonth = (month) => {
  let string = '';
  switch(month) {
  case 0:
    string = 'January';
    break;
  case 1:
    string = 'February';
    break;
  case 2:
    string = 'March';
    break;
  case 3:
    string = 'April';
    break;
  case 4:
    string = 'May';
    break;
  case 5:
    string = 'June';
    break;
  case 6:
    string = 'July';
    break;
  case 7:
    string = 'August';
    break;
  case 8:
    string = 'September';
    break;
  case 9:
    string = 'October';
    break;
  case 10:
    string = 'November';
    break;
  case 11:
    string = 'December';
    break;
  }
  return string;
};

export const findSmallMonth = (month) => {
  let string = '';
  switch(month) {
  case 0:
    string = 'Jan';
    break;
  case 1:
    string = 'Feb';
    break;
  case 2:
    string = 'Mar';
    break;
  case 3:
    string = 'Apr';
    break;
  case 4:
    string = 'May';
    break;
  case 5:
    string = 'Jun';
    break;
  case 6:
    string = 'Jul';
    break;
  case 7:
    string = 'Aug';
    break;
  case 8:
    string = 'Sep';
    break;
  case 9:
    string = 'Oct';
    break;
  case 10:
    string = 'Nov';
    break;
  case 11:
    string = 'Dec';
    break;
  }
  return string;
};

export const getNewDate = (string) => moment(string, 'YYYY/MM/DD');

export const getActivityDate = (method, year) => {
  let obj = {};
  switch(method) {
  case 'Jan':
    obj.startDate = `01-01-${year}`;
    obj.endDate = `01-31-${year}`;
    break;

  case 'Feb':
    obj.startDate = `02-01-${year}`;
    obj.endDate = (year%4) === 0 ? `02-29-${year}` : `02-28-${year}`;
    break;

  case 'Mar':
    obj.startDate = `03-01-${year}`;
    obj.endDate = `03-31-${year}`;
    break;

  case 'Apr':
    obj.startDate = `04-01-${year}`;
    obj.endDate = `04-30-${year}`;
    break;

  case 'May':
    obj.startDate = `05-01-${year}`;
    obj.endDate = `05-31-${year}`;
    break;

  case 'Jun':
    obj.startDate = `06-01-${year}`;
    obj.endDate = `06-30-${year}`;
    break;

  case 'Jul':
    obj.startDate = `07-01-${year}`;
    obj.endDate = `07-31-${year}`;
    break;

  case 'Aug':
    obj.startDate = `08-01-${year}`;
    obj.endDate = `08-31-${year}`;
    break;

  case 'Sep':
    obj.startDate = `09-01-${year}`;
    obj.endDate = `09-31-${year}`;
    break;

  case 'Oct':
    obj.startDate = `10-01-${year}`;
    obj.endDate = `10-30-${year}`;
    break;

  case 'Nov':
    obj.startDate = `11-01-${year}`;
    obj.endDate = `11-30-${year}`;
    break;

  case 'Dec':
    obj.startDate = `12-01-${year}`;
    obj.endDate = `12-31-${year}`;
    break;

  case 'Q1':
    obj.startDate = `01-01-${year}`;
    obj.endDate = `03-31-${year}`;
    break;

  case 'Q2':
    obj.startDate = `04-01-${year}`;
    obj.endDate = `06-30-${year}`;
    break;

  case 'Q3':
    obj.startDate = `07-01-${year}`;
    obj.endDate = `09-31-${year}`;
    break;

  case 'Q4':
    obj.startDate = `10-01-${year}`;
    obj.endDate = `12-31-${year}`;
    break;

  case 'year':
    obj.startDate = `01-01-${year}`;
    obj.endDate = `12-31-${year}`;
    break;
  }
  return obj;
};

export const parseRank = (rank) => {
  let lang = localStorage.getItem("lang");
  let str = '';
  // str = lang == 'fr'? 'e' : lang == 'es' ? 'er' : lang == 'pt' ? 'º' : 'st';
  switch(rank) {
  case 1:
    str = lang == 'fr'? 'e' : 'st';
    break;
  case 2:
    str = lang == 'fr'? 'e' : 'nd';
    break;
  case 3:
    str = lang == 'fr'? 'e' : 'rd';
    break;
  default:
    str = lang == 'fr'? 'e' : 'th';
    break;
  }
  return str;
};

export const dateOrdinal = (rank) => {
  let str = '';
  if (rank > 3 && rank < 21){
    str = 'th';
  } else {
    switch (rank % 10) {
    case 1:
      str = 'st';
      break;
    case 2:
      str = 'nd';
      break;
    case 3:
      str = 'rd';
      break;
    default:
      str = 'th';
      break;
    }
  }
  return str;
};

export const rankingDashboard = (rank,language="en") => {
  let newRank = rank + 1;
  if((rank+1) > 100) {
    newRank = (rank+1) % 100;
  }

  return newRank > 10 && newRank <20 ? parseRank(newRank,language) : parseRank(newRank%10);
};

export const phoneNumberMaker = (phoneNo) => {
  let arr = phoneNo.split('-');
  let str = ''
  for(let i=0;i<arr.length;i++) {
    str += arr[i];
  }
  return str;
}

export const displayPhoneNumber = (phoneNo) => {
  if(String(phoneNo).length  === 10) {
    let arr = [];
    arr[0] = phoneNo.slice(0,3);
    arr[1] = phoneNo.slice(3,6);
    arr[2] = phoneNo.slice(6,10);
    return arr[0] + '-' + arr[1] + '-' + arr[2];
  }
  else {
    return phoneNo;
  }  
}

export const getCountofCalendar = (month, year) => {
  let obj = {};
  switch(month) {

  case 'Jan':
    obj.totalDay = 31;
    obj.emptyGrid = new Date(moment(`01/01/${year}`, "MM DD YYYY")._d).getDay();
    obj.month = '01';
    break;
  case 'Feb':
    obj.totalDay =  (year%4) === 0 ? 29 : 28;
    obj.emptyGrid = new Date(moment(`02/01/${year}`, "MM DD YYYY")._d).getDay();
    obj.month = '02';
    break;
  case 'Mar':
    obj.totalDay = 31;
    obj.emptyGrid = new Date(moment(`03/01/${year}`, "MM DD YYYY")._d).getDay();
    obj.month = '03';
    break;
  case 'Apr':
    obj.totalDay = 30;
    obj.emptyGrid = new Date(moment(`04/01/${year}`, "MM DD YYYY")._d).getDay();
    obj.month = '04';
    break;
  case 'May':
    obj.totalDay = 31;
    obj.emptyGrid = new Date(moment(`05/01/${year}`, "MM DD YYYY")._d).getDay();
    obj.month = '05';
    break;
  case 'Jun':
    obj.totalDay = 30;
    obj.emptyGrid = new Date(moment(`06/01/${year}`, "MM DD YYYY")._d).getDay();
    obj.month = '06';
    break;
  case 'Jul':
    obj.totalDay = 31;
    obj.emptyGrid = new Date(moment(`07/01/${year}`, "MM DD YYYY")._d).getDay();
    obj.month = '07';
    break;
  case 'Aug':
    obj.totalDay = 31;
    obj.emptyGrid = new Date(moment(`08/01/${year}`, "MM DD YYYY")._d).getDay();
    obj.month = '08';
    break;
  case 'Sep':
    obj.totalDay = 30;
    obj.emptyGrid = new Date(moment(`09/01/${year}`, "MM DD YYYY")._d).getDay();
    obj.month = '09';
    break;
  case 'Oct':
    obj.totalDay = 31;
    obj.emptyGrid = new Date(moment(`10/01/${year}`, "MM DD YYYY")._d).getDay();
    obj.month = '10';
    break;
  case 'Nov':
    obj.totalDay = 30;
    obj.emptyGrid = new Date(moment(`11/01/${year}`, 'MM-DD-YYYY')._d).getDay();
    obj.month = '11';
    break;
  case 'Dec':
    obj.totalDay = 31;
    obj.emptyGrid = new Date(moment(`12/01/${year}`, "MM DD YYYY")._d).getDay();
    obj.month = '12';
    break;
  }

  return obj;
}

export const checkPermission = () => {
  try {
    return localStorage ? true : false;
  } catch(e) {
    return false;
  }
}

export const checkImage = (file) => {
  if(file[0]) {
    let nameOfFile = file[0].name.split('.');
    let lastIndex = nameOfFile[nameOfFile.length - 1];
    return lastIndex.toUpperCase() === 'PNG' || lastIndex.toUpperCase() === 'JPG' || lastIndex.toUpperCase() === 'JPEG' ? true : false;
  } else {
    return false;
  }
}
export const checkMultipleImage = (file) => {
  if(file) {
    let nameOfFile = file.name.split('.');
    let lastIndex = nameOfFile[nameOfFile.length - 1];
    return lastIndex.toUpperCase() === 'PNG' || lastIndex.toUpperCase() === 'JPG' || lastIndex.toUpperCase() === 'JPEG' ? true : false;
  } else {
    return false;
  }
}
export const GetSortedData = (prop) => (
  function(a, b) {  
    if (a[prop] < b[prop]) {  
      return 1;  
    } else if (a[prop] > b[prop]) {  
      return -1;  
    }  
    return 0;  
  }  
)

export const getOrdinalNum = (num) => (num > 0 ? ['th', 'st', 'nd', 'rd'][(num > 3 && num < 21) || num % 10 > 3 ? 0 : num % 10] : '');

export const calculateChangeInData = (average, lastYearAverage) => {
  let object = {};
  if(_.isNull(average) || _.isUndefined(average) || _.isNull(lastYearAverage) || _.isUndefined(lastYearAverage)) {
    object = {value: '0%', trendIndicator: null};
  }
  else if (average > lastYearAverage) {
    object =  {value: `+${Math.abs(average - lastYearAverage)}%`, trendIndicator: 1}
  }
  else if (average < lastYearAverage) {
    object = {value: `-${Math.abs(average - lastYearAverage)}%`, trendIndicator: -1}
  }
  else {
    object =  {value: `${Math.abs(average - lastYearAverage)}%`, trendIndicator: 0}
  }
  return object;
};

export const themeHeader = [
  {
    id: 0,
    month: 'January',
    background: '#7164C4',
    themeText: 'January Focus is Blood Donation',
    imgUrl: '/public/images/ThemeIcons/icon_january.svg'
  },
  {
    id:1,
    month: 'February',
    background: '#E48D8B',
    themeText: 'February Focus is Heart Health',
    imgUrl: '/public/images/ThemeIcons/icon_february.svg'
  },
  {
    id:2,
    month: 'March',
    background: '#C464AC',
    themeText: 'March Focus is Nutrition',
    imgUrl: '/public/images/ThemeIcons/icon_march.svg'
  },
  {
    id:3,
    month: 'April',
    background: '#4A9DC6',
    themeText: 'April Focus is Stress',
    imgUrl: '/public/images/ThemeIcons/icon_april.svg'
  },
  {
    id:4,
    month: 'May',
    background: '#1E1977',
    themeText: ' May Focus is Mental Health',
    imgUrl: '/public/images/ThemeIcons/icon_may.svg'
  },
  {
    id:5,
    month: 'June',
    background: '#8BBD53',
    themeText: 'June Focus is Men’s Health',
    imgUrl: '/public/images/ThemeIcons/icon_june.svg'
  },
  {
    id:6,
    month: 'July',
    background: '#7164C4',
    themeText: 'July Focus is Family Fitness',
    imgUrl: '/public/images/ThemeIcons/icon_july.svg'
  },
  {
    id:7,
    month: 'August',
    background: '#E48D8B',
    themeText: 'August focus is World Friendship Day',
    imgUrl: '/public/images/ThemeIcons/icon_august.svg'
  },
  {
    id:8,
    month: 'September',
    background: '#C464AC',
    themeText: 'September Focus is Cholesterol Awareness',
    imgUrl: '/public/images/ThemeIcons/icon_september.svg'
  },
  {
    id:9,
    month: 'October',
    background: '#4A9DC6',
    themeText: 'October Focus is Women’s Health',
    imgUrl: '/public/images/ThemeIcons/icon_october.svg'
  },
  {
    id:10,
    month: 'November',
    background: '#1E1977',
    themeText: 'November Focus is Diabetes',
    imgUrl: '/public/images/ThemeIcons/icon_november.svg'
  },
  {
    id:11,
    month: 'December',
    background: '#8BBD53',
    themeText: 'December Focus is Family Health',
    imgUrl: '/public/images/ThemeIcons/icon_december.svg'
  },
];

export const getMeters = (miles) => miles * 1609.344;

export const getChallengePoint = (point) => {
  let value = (point === 500) ? 'Tier 4: 500 points' : (point === 250) ? 'Tier 3: 250 points' : (point === 100) ? 'Tier 2: 100 points' : 'Tier 1: 25 points';
  return value;
};

export const setHexCode = () => `#${('00000'+(Math.random()*0xFFFFFF<<0).toString(16)).slice(-6)}`;

export const youTubeGetID = (url) => {
  let ID = '';
  url = url.replace(/(>|<)/gi,'').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  if(url[2] !== undefined) {
    ID = url[2].split(/[^0-9a-z_\-]/i);
    ID = ID[0];
  }
  else {
    ID = url;
  }
  return ID;
}

export const getPermissionStatus = (feature, userPermissions) => !_.isNull(userPermissions) && !_.isUndefined(userPermissions) && userPermissions.length > 0 && userPermissions.some((data) => feature === data);

export const getChallengeTimeShow = (item) => {
  let result = 0;
  let newResult = [];
  if (convertDateInTimezone(item?.start_date)._d <= moment()._d) {
    result = Math.abs(getChallengeDays(convertDateInTimezone(item?.end_date)._d, moment()._d));
    if(result === 0){
      newResult.push("Last Day");
    } else if( result === 1){
      newResult.push(result + " " + "Day");
    }
  } else if (convertDateInTimezone(item?.start_date).add(1, 'days')._d > moment()._d) {
    result = Math.abs(getChallengeDays(convertDateInTimezone(item?.start_date).add(1, 'days')._d, moment()._d));
    if(result === 1){
      newResult.push(result +" "+ "Days");
    }
  }
  if(result > 1){
    newResult.push(result +" "+ "Days");
  }
  return newResult;
}

export const getChallengeTimeShowNew = (item, props) => {
  let result = 0;
  let newResult = [];
  if (convertDateInTimezone(item?.start_date)._d <= moment()._d) {
    result = Math.abs(getChallengeDays(convertDateInTimezone(item?.end_date)._d, moment()._d));
    if(result === 0){
      newResult.push(props.t("Last Day"));
    } else if( result === 1){
      newResult.push(result + " " + props.t("Day"));
    }
  } else if (convertDateInTimezone(item?.start_date).add(1, 'days')._d > moment()._d) {
    result = Math.abs(getChallengeDays(convertDateInTimezone(item?.start_date).add(1, 'days')._d, moment()._d));
    if(result === 1){
      newResult.push(result +" "+ props.t("Days"));
    }
  }
  if(result > 1){
    newResult.push(result +" "+ props.t("Days"));
  }
  return newResult;
}

export const convertMilesToKm = (values) => {
  if(_.isNull(values) || _.isUndefined(values)) {
    return
  }
  else {
    let realKm = ( values * 1.609344 );
    // let kiloM = Math.floor(realKm);
    return realKm.toFixed(2);
  }
};

export const getKilometers = (km) => km * 1000;

export const convertHtmlToText = (html)=> {
  const sanitizedHtml = DOMPurify.sanitize(html);
  
  const docFragment = document.createRange().createContextualFragment(sanitizedHtml);
  
  const container = document.createElement('div');
  container.appendChild(docFragment);

  return container.textContent || container.innerText || '';
  
  // const element = document.createElement('div');
  // element.innerHTML = html;
  // return element.textContent || element.innerText || '';
}


export const fetchApi = async (endpoint, method = 'GET', payload = null,additionalHeaders={}, successFunc=null) => {
  const url = `${BASE_URL}${endpoint}`;
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  const headers = {
    'Content-Type': 'application/json',
    'AuthToken':AuthToken,
    ...additionalHeaders
    // Add any other headers you need (e.g., authorization tokens)
  };

  const options = {
    method,
    headers,
    body: payload ? JSON.stringify(payload) : null,
  };

  try {
    const response = await fetch(url, options);
    if(response.ok){
      if(successFunc){
        successFunc()
      }
    }else{
      // toast.error('API request failed');
    }

    return response.json();
  } catch (error) {
    console.error('API request failed:', error.message);
    throw error;
  }
};

// To translate month from date string, takes date string and props to access t() function.
export const convertMonthFromDate = (dateString, props) => {
  let monthsArray = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "January,", "February,", "March,", "April,", "May,", "June,", "July,", "August,", "September,", "October,", "November,", "December,", "SUN","MON","TUE","WED","THU","FRI","SAT","Sun","Mon","Tue","Wed","Thu","Fri","Sat","SUN,","MON,","TUE,","WED,","THU,","FRI,","SAT,","Sun,","Mon,","Tue,","Wed,","Thu,","Fri,","Sat,",];

  let temp = dateString.split(" ");

  for(let i = 0 ;  i < temp.length ; i++){ 
    if(monthsArray.includes(temp[i])){
      temp[i] =  props.t(temp[i]);
    }
  }
  temp = temp.join(" ");
  return temp;
}

export const convertMonthsWeeksText = (string, t) => {
  let wordsArray = ["months", "weeks", "hours", "minutes"];
  let stringArray = string.split(" ");
  for(let i = 0 ;  i < stringArray.length ; i++){ 
    if(wordsArray.includes(stringArray[i])){
      stringArray[i] =  t(stringArray[i]);
    }
  }
  stringArray = stringArray.join(" ");
  window.console.log("line 10000 :- stringArray :- ", stringArray)  
  return stringArray;
}

export const createSafeHTML = (htmlString) => {
  const sanitizedHTML = DOMPurify.sanitize(htmlString);
  return ReactHtmlParser(sanitizedHTML); 
};

export const timeConvertInHrsMins = (n) => {
  let num = n;
  let days = Math.floor(num / 1440); // 1440 minutes in a day
  let hours = Math.floor((num % 1440) / 60);
  let minutes = num % 60;
  let result = "";

  if (days > 0) {
    result += days + (days>1?" days ": " day ");
  }

  if (hours > 0) {
    result += hours + (hours>1?" hrs ": " hr ");
  }

  if (minutes > 0) {
    result += minutes + (minutes>1?" mins": " min");
  }

  return result.trim();
};



export const timeConvertInHrsMinsSec = (totalSeconds) => {
  const days = Math.floor(totalSeconds / 86400); 
  const hours = Math.floor((totalSeconds % 86400) / 3600); 
  const minutes = Math.floor((totalSeconds % 3600) / 60); 
  const seconds = totalSeconds % 60;

  let result = "";

  if (days > 0) {
    result += days + (days > 1 ? " days " : " day ");
  }

  if (hours > 0) {
    result += hours + (hours > 1 ? " hrs " : " hr ");
  }

  if (minutes > 0) {
    result += minutes + (minutes > 1 ? " mins " : " min ");
  }

  if (seconds > 0 && totalSeconds < 3600) { 
    result += seconds + (seconds > 1 ? " secs" : " sec");
  }

  return result.trim();
};



export const getInitials = (fullName) => {
  if (!fullName) return "";
  const names = fullName.split(" ");
  const initials = names.map((name) => name.charAt(0).toUpperCase());
  return initials.join("").slice(0, 2);
};



export const getStartAndEndDates = (interval) => {
  const currentDate = moment();
  let startDate, endDate;

  if (interval === "Monthly") {
    startDate = currentDate.startOf("month").format("YYYY-MM-DD");
    endDate = currentDate.endOf("month").format("YYYY-MM-DD");
  } else if (interval === "Quarterly") {
    startDate = currentDate.startOf("quarter").format("YYYY-MM-DD");
    endDate = currentDate.endOf("quarter").format("YYYY-MM-DD");
  } else if (interval === "Yearly") {
    startDate = currentDate.startOf("year").format("YYYY-MM-DD");
    endDate = currentDate.endOf("year").format("YYYY-MM-DD");
  }

  return { startDate, endDate };
};

