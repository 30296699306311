/* eslint-disable no-console,no-undef,camelcase,no-useless-escape */
import moment from "moment-timezone";

export const ImageUrl = process.env.ENVIRONMENT === "production" ? "https://d33e486p3yey9v.cloudfront.net" :process.env.ENVIRONMENT === "sandbox" || process.env.ENVIRONMENT === "staging" ? "https://d38xnw03cl4zf4.cloudfront.net" : 'https://ddf0gygzuiev4.cloudfront.net';

export const ImageUrlNew =  process.env.ENVIRONMENT === "production" ? "https://d33e486p3yey9v.cloudfront.net" :process.env.ENVIRONMENT === "sandbox" || process.env.ENVIRONMENT === "staging" ? "https://d38xnw03cl4zf4.cloudfront.net" : 'https://ddf0gygzuiev4.cloudfront.net';

export const S3Url = process.env.ENVIRONMENT === "production" ? "https://s3.amazonaws.com/images.trainingamigo.com" :process.env.ENVIRONMENT === "sandbox" || process.env.ENVIRONMENT === "staging" ? "https://woliba-sandbox.s3.amazonaws.com" : 'https://trainingamigo-dev.s3.amazonaws.com';

export const AllRoutes = [
  "integration",
  "pricing",
  "about",
  "portal",
  "events",
  "challenges",
  "social",
  "education",
  "leaderboard",
  "rewards",
  "user",
  "privacy-policy",
  "terms-use",
  "blogs",
  "contact",
  "wellness-assessment",
  "profile",
  "community",
  "settings",
  "company",
  "wellness-expert",
  "survey",
  "workout-history",
  "node",
  "manager",
  "wellness-resource"
];

export const TimeZone = moment.tz.guess();

export const GOOGLE_API_KEY = "AIzaSyAW2ilZNq7MjvJeoMZPDaCiZf2292qa5MI";

export const CompanyIdForWellnessProgram2019 = 11;
export const CompanyIdForACME = 18;

export const EventLimitPerPage = 50;

export const FeedsLimitPerPage = 10;

export const AmigosLimitPerPage = 10;

const Origin = window.location.origin;
export const REDIRECT_URI = `${Origin}/portal`;

/* FitBit Constants */
export const FITBIT_CLIENT_ID = "22D7JR";
export const FITBIT_SECRET = "fad2c7441829dfbf6379666d17185a8c";
export const FITBIT_AUTHORIZATION_URI =
  "https://www.fitbit.com/oauth2/authorize";
export const FITBIT_SCOPE =
  "activity heartrate location nutrition profile settings sleep social weight";
export const FITBIT_EXPIRATION_TIME = "604800";

export const FITBIT_URI = `${FITBIT_AUTHORIZATION_URI}?scope=${FITBIT_SCOPE}&expires_in=${FITBIT_EXPIRATION_TIME}`;
export const FITBIT_TOKEN_URI = "https://api.fitbit.com/oauth2/token";

/* Strava Constants*/
export const STRAVA_AUTHORIZATION_URI =
  "https://www.strava.com/oauth/authorize";
// export const STRAVA_CLIENT_ID = "28853";
//export const STRAVA_CLIENT_ID = "40131";
export const STRAVA_CLIENT_ID = "53822";
// export const STRAVA_CLIENT_SECRET = "f0280592f9d30779932b1da6a0226261df46a054";
export const STRAVA_CLIENT_SECRET = "6cac53132e3b05d342b9cd282129d65c399f9ff6";
export const STRAVA_TOKEN_URL = "https://www.strava.com/oauth/token";

export const STRAVA_URI = `${STRAVA_AUTHORIZATION_URI}?approval_prompt=force`;

export const WITHINGS_CLIENT_ID =
  "7e68400c463c3d31ca8677424d4b7baadfcf6ddcc1315552e9eb2fb25f987b83";
export const WITHINGS_CONSUMER_SECRET =
  "35b9ffe1144469105e3eb38e6a3dfffc72f3dbd7d3f52d82009f0132ef4c7521";
export const WITHINGS_REQUEST_TOKEN_URL =
  "https://oauth.withings.com/account/request_token";
export const WITHINGS_ACCESS_TOKEN_URL =
  "https://oauth.withings.com/account/access_token";
export const WITHINGS_USER_AUTHENTICATION =
  "https://oauth.withings.com/account/authorize";

export const WITHINGS_AUTH_URL =
  "https://account.withings.com/oauth2_user/authorize2";
export const WITHINGS_URI = `${WITHINGS_AUTH_URL}?response_type=code&scope=user.activity,user.metrics,user.info`;
export const WITHINGS_TOKEN = "https://account.withings.com/oauth2/token";

export const GARMIN_CONSUMER_KEY = "6c1f32c5-4c16-4144-aa88-5f922c713de6";
//export const GARMIN_CONSUMER_KEY = '422b5928-4390-4274-b455-653367c569df';
export const GARMIN_SECRET = "0S3lDeSspSkh10NcnL4mlQuSWR49liQBbuj";
//export const GARMIN_SECRET = 'hh55fCxjxKwXkFoZUmTWW0hh8GWrqJE4jRE';
export const GARMIN_REQUEST_TOKEN_URI =
  "https://connectapi.garmin.com/oauth-service/oauth/request_token";
export const GARMIN_ACCESS_TOKEN_URI =
  "https://connectapi.garmin.com/oauth-service/oauth/access_token";
export const GARMIN_USER_AUTHENTICATION =
  "https://connect.garmin.com/oauthConfirm";

/* Image Path */

export const imgPath = "/public/images";
export const aboutPageImagePath = imgPath + "AboutPage/";
export const teamImagePath = imgPath + "team/";
export const coreValueImagePath = imgPath + "coreValues/";
export const OvalShapes = imgPath + "OvalShapes/";
export const educationAdventure = imgPath + "/educationAdventureIcons/";

/* Download Path */
export const downloadPath = "/public/downloads/";

/**Error messages*/

export const imageErrorMessage = "Please select image file";

/*page Limit for challenge leaderboard*/
export const leaderboardPageLimit = 25;

/*Behaviour challenge categories*/
export const lifeStyle = "Lifestyle";
export const health = "Health";
export const stress = "Stress";
export const nutrition = "Nutrition";
export const workPlace = "Workplace";
export const fitness = "Fitness";

// SEO Schema Markup
export const seoSchema = {
  "@context": "https://schema.org",
  "@type": "Organization",
  name: "Woliba",
  url: "https://woliba.io/",
  logo: "https://woliba.io/images/public/Logo/Woliba.svg",
  sameAs: [
    "https://www.facebook.com/wolibawellness/",
    "https://twitter.com/wolibawellness",
    "https://www.instagram.com/wolibawellness/",
    "https://www.youtube.com/channel/UC85Ln59cEHRwbewzWWCzolw",
    "https://www.linkedin.com/company/wolibawellness/",
  ],
};

export const WellnessLevels = [{id:1, level:1},{id:2, level:2},{id:3, level:3},{id:4, level:4},{id:5, level:5},{id:6, level:6}]